import { CheckoutBody, SessionData } from "./interfaces";

class ServiceCallsAPI {
  callCheckout(idempotencyKey: string, body: CheckoutBody) {
    return fetch(`/payments/checkout?idempotencyKey=${idempotencyKey}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  }

  callCheckoutUpdate(
    idempotencyKey: string,
    body: CheckoutBody,
    paymentID: string,
  ) {
    return fetch(
      `/payments/checkout/${paymentID}?idempotencyKey=${idempotencyKey}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    );
  }

  callAssets(type: "in-honour" | "in-memory") {
    return fetch(`/presentation/assets?type=png&category=${type}`);
  }

  callRecaptcha(body: { token: string; action: string }) {
    return fetch("/payments/captcha", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  }

  callPresValue(presValue: string | null) {
    return fetch(`/presentation/${presValue}`);
  }

  callLookupId(lookupId: string, presValue: string) {
    return fetch(`/constituents/${lookupId}/${presValue}`);
  }

  callCid(cid: string, authenticationToken: string) {
    return fetch(`/constituents/${cid}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + authenticationToken,
      },
    });
  }

  callAttribution(email: string) {
    return fetch(
      `/constituents/attributions?email=${encodeURIComponent(email)}`,
    );
  }

  callSession(data: SessionData) {
    return fetch("/constituents/cart-abandon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  callGetAuthToken() {
    return fetch("/.auth/me");
  }

  callRefreshToken() {
    return fetch("/.auth/refresh");
  }
}

export default new ServiceCallsAPI();
