import React, { useEffect } from "react";
import { selectFormLanguage } from "redux/form/form.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import GlobalStyle from "global";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { AppContainer } from "app.styles";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "components/appinsights/appinsights";

import Header from "components/header/header.component";
import DonationPage from "pages/donation/donation-page.component";
import ThankYouPage from "pages/thank-you/thank-you.component";
import Popup from "components/popup/popup.component";
import ErrorPage from "pages/error-page/error-page.component";
import {
  selectIsFormDisabled,
  selectViewportHeight,
} from "redux/helpers/helpers.selector";
import ProtectedRoute from "components/protected-route/protected-route.component";
import { updateViewportHeight } from "redux/helpers/helpers.reducer";
import SessionManager from "components/session-manager/session-manager.component";

const App: React.FC = () => {
  const queryString = useLocation().search;
  const language = useAppSelector(selectFormLanguage);
  const isFormDisabled = useAppSelector(selectIsFormDisabled);
  const viewportHeight = useAppSelector(selectViewportHeight);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const resizeListener = () => {
      dispatch(updateViewportHeight(window.innerHeight));
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []); // eslint-disable-line

  return (
    <AppContainer isFormDisabled={isFormDisabled} className="App">
      <AppInsightsContext.Provider value={reactPlugin}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
          useEnterprise={true}
          language={language}
        >
          <GlobalStyle />
          <SessionManager />
          <Header />
          <Routes>
            <Route
              path="/"
              element={<DonationPage viewportHeight={viewportHeight} />}
            />
            <Route
              path="/thank-you"
              element={<ThankYouPage viewportHeight={viewportHeight} />}
            />
            <Route
              path="/error"
              element={
                <ProtectedRoute>
                  <ErrorPage viewportHeight={viewportHeight} />
                </ProtectedRoute>
              }
            />
            <Route path="/.auth/logout" element={<p>Loggin out...</p>} />
            <Route
              path="*"
              element={<Navigate to={`/${queryString}`} replace />}
            />
          </Routes>
          <Popup />
        </GoogleReCaptchaProvider>
      </AppInsightsContext.Provider>
    </AppContainer>
  );
};

export default App;
