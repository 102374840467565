import React, { Suspense, lazy, useEffect, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Aeroplan from "components/aeroplan/aeroplan.component";
import {
  selectCoverTransactionFee,
  selectDonationAmount,
  selectDonationInfo,
  selectFormErrors,
  selectLoyaltyCardNumber,
  selectIsTributeGift,
} from "redux/form/form.selector";
import {
  selectAssetsAvailable,
  selectCurrentForm,
  selectIsCoverFeeSupported,
  selectIsPointsCardSupported,
  selectMonthlySuggestionText,
  selectPointsCardType,
  selectPopup,
  selectPresValue,
} from "redux/helpers/helpers.selector";
import {
  updateCoverTransactionFee,
  updateDonationAmount,
  updateDonationType,
  updateFormErrors,
  updateIsTributeGift,
  updateLoyaltyCardNumber,
  updatePaymentMethod,
} from "redux/form/form.reducer";
import { updateCurrentForm, updatePopup } from "redux/helpers/helpers.reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  handleAskAmountsUpdate,
  convertNumberToDecimal,
  scrollToTheError,
  scrollToTheTop,
  validate,
} from "utils/helper-functions";
import {
  ValidationErrors,
  DonationType as TDonationType,
  PointsCardVariant,
} from "utils/interfaces";
import { schemaConstructor } from "utils/validation-schemas";

import {
  TypeButtonsContainer,
  TypeButton,
  AmountButtonsContainer,
  AmountButton,
  OtherInputContainer,
  OtherLabel,
  DonationTypeSection,
  AmountSection,
  CoverFeeContainer,
  CoverFeeText,
  HorizontalLine,
  CCReportTributeLink,
} from "./donation-type.styles";

import {
  ErrorMessage,
  NextStepButton,
  BottomButtonsContainer,
  SectionHeading,
  CheckboxContainer,
  FormPartContainer,
} from "global";
import { useTranslation } from "react-i18next";
import {
  selectAskAmounts,
  selectIsHonourSupported,
  selectIsMemorySupported,
  selectIsMonthlySupported,
  selectIsOneTimeSupported,
} from "redux/presValue/presValue.selector";
import { updateAskAmounts } from "redux/presValue/presValue.reducer";
import { useSearchParams } from "react-router-dom";
import MonthlySuggestion from "components/monthly-suggestion/monthly-suggestion.component";
import analyticsAPI from "utils/analyticsAPI";
import { popupMessages } from "utils/variables";
import { ValidationError } from "yup";

const TributeForm = lazy(
  () => import("components/tribute-form/tribute-form.component"),
);

const DonationType: React.FC = () => {
  // URL SEARCH PARAMS
  const [searchParams] = useSearchParams();
  const askActive = searchParams.get("ask") || searchParams.get("s_Ask");
  const chosenAsk =
    searchParams.get("chosenAsk") || searchParams.get("s_chosenAsk");

  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const formErrors = useAppSelector(selectFormErrors);
  const isTributeGift = useAppSelector(selectIsTributeGift);
  const isMemorySupported = useAppSelector(selectIsMemorySupported);
  const isHonourSupported = useAppSelector(selectIsHonourSupported);
  const isMonthlySupported = useAppSelector(selectIsMonthlySupported);
  const isOneTimeSupported = useAppSelector(selectIsOneTimeSupported);
  const askAmounts = useAppSelector(selectAskAmounts);
  const donationInfo = useAppSelector(selectDonationInfo);
  const donationAmount = useAppSelector(selectDonationAmount);
  const coverTransactionFee = useAppSelector(selectCoverTransactionFee);
  const monthlySuggestionText = useAppSelector(selectMonthlySuggestionText);
  const isCoverFeeSupported = useAppSelector(selectIsCoverFeeSupported);
  const presValue = useAppSelector(selectPresValue);
  const assetsAvailable = useAppSelector(selectAssetsAvailable);
  const isPointsCardSupported = useAppSelector(selectIsPointsCardSupported);
  const pointsCardType = useAppSelector(selectPointsCardType);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const popup = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();

  // LOCAL STATE
  const [isOthersActive, setIsOthersActive] = useState(false);
  const [othersValue, setOthersValue] = useState<string>("");
  const [activeAskButton, setActiveAskButton] = useState<number>();
  const { t } = useTranslation();

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (askActive) {
      if (+askActive <= 3 && +askActive > 0) {
        setActiveAskButton(+askActive);
        dispatch(
          updateDonationAmount(
            askAmounts[donationInfo.donationType || "one-time"][+askActive - 1],
          ),
        );
      } else if (+askActive === 4) {
        setActiveAskButton(0);
        setIsOthersActive(true);
      }
    }

    if (chosenAsk && !isNaN(+chosenAsk)) {
      +chosenAsk < 999999999
        ? setOthersValue(chosenAsk)
        : setOthersValue("999999999");
      dispatch(updateDonationAmount(+chosenAsk));
      setActiveAskButton(0);
      setIsOthersActive(true);
    }
  }, []); // eslint-disable-line

  const handleAmountClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const target = e.currentTarget;
    analyticsAPI.handleAskSelect(appInsights, e);
    if (target.dataset.id && activeAskButton !== +target.dataset.id) {
      dispatch(updateDonationAmount(+target.value));
      setIsOthersActive(false);
      setOthersValue("");
      setActiveAskButton(+target.dataset.id);
    }
  };

  const handleResetAskAmount = () => {
    setActiveAskButton(0);
    dispatch(updateDonationAmount(0));
    dispatch(updateCoverTransactionFee(false));

    // Disable cover fees when type is changed
    if (coverTransactionFee) handleTransactionFee(false);
  };

  const handleTypeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value } = e.currentTarget;

    // Guard clause
    if (donationInfo.donationType === value) return;

    if (donationInfo.donationType && !isOthersActive) {
      handleResetAskAmount();
    } else {
      if (value !== "one-time" && !isOthersActive) {
        handleResetAskAmount();
      }
    }

    // Update type
    if (value === "monthly" || value === "one-time") {
      dispatch(updateDonationType(value));
      dispatch(updatePaymentMethod("card"));
    }

    // Collapse In Honour in case of monthly donation
    if (value === "monthly") dispatch(updateIsTributeGift(false));

    if (renderPointsCard()) {
      dispatch(updateLoyaltyCardNumber(""));
      dispatch(updateFormErrors({}));
    }
  };

  const handleNextForm = async (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    analyticsAPI.handleNextStep(appInsights, { currentForm });
    const schema = schemaConstructor("donationType", { loyaltyCardNumber });

    try {
      await validate(
        {
          donationAmount: donationAmount,
          donationType: donationInfo.donationType,
          honourType: donationInfo.honourType,
          honoureeName: donationInfo.honoureeName,
          recipientEmail: donationInfo.recipientEmail,
          emailSubject: donationInfo.emailSubject,
          cardActive: donationInfo.cardActive,
          honoureeAddressLine: donationInfo.honoureeAddressLine,
          honoureeCity: donationInfo.honoureeCity,
          honoureeProvinceState: donationInfo.honoureeProvinceState,
          honoureeCountry: donationInfo.honoureeCountry,
          honoureePostal: donationInfo.honoureePostal,
          cardRecipientFullName: donationInfo.cardRecipientFullName,
          isTributeGift: donationInfo.isTributeGift,
          honourCardType: donationInfo.honourCardType,
          loyaltyCardNumber: donationInfo.loyaltyCardNumber,
        },
        schema,
      );

      dispatch(updateCurrentForm(currentForm + 1));
      dispatch(updateFormErrors({}));

      // Scroll to the top
      scrollToTheTop();
    } catch (error: unknown) {
      const errors: ValidationErrors = {};

      (error as ValidationError).inner.forEach((err) => {
        if (err.path) {
          errors[err.path] = err.message;
        }
      });

      scrollToTheError(Object.keys(errors)[0]);

      // Display popup
      dispatch(updateFormErrors(errors));
      dispatch(
        updatePopup({
          ...popup,
          message: popupMessages.errorsFixed,
          isError: true,
          isActive: true,
          isLoading: false,
        }),
      );
    }
  };

  const handleTransactionFee = (isAskActive = true) => {
    // Update donation amount in the state
    if (isAskActive || othersValue)
      dispatch(
        updateDonationAmount(
          coverTransactionFee
            ? convertNumberToDecimal(donationAmount - 2)
            : convertNumberToDecimal(donationAmount + 2),
        ),
      );

    // Others field if it is active
    if (othersValue)
      setOthersValue(
        `${coverTransactionFee ? convertNumberToDecimal(+othersValue - 2) : convertNumberToDecimal(+othersValue + 2)}`,
      );

    // Update the ask amounts
    dispatch(
      updateAskAmounts({
        "one-time": handleAskAmountsUpdate(
          askAmounts,
          coverTransactionFee,
          "one-time",
        ),
        monthly: handleAskAmountsUpdate(
          askAmounts,
          coverTransactionFee,
          "monthly",
        ),
      }),
    );

    dispatch(updateCoverTransactionFee(!coverTransactionFee));
  };

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value.length === 10) return;

    if (coverTransactionFee) {
      dispatch(updateCoverTransactionFee(!coverTransactionFee));
      handleTransactionFee();
    }

    // Allow only numeric values and a single dot
    const numericRegex = /^$|^\d+(\.\d{0,2})?$/;

    if (numericRegex.test(value)) {
      dispatch(updateDonationAmount(+value));
      setOthersValue(value);
      setActiveAskButton(0);
      setIsOthersActive(true);
    }
  };

  const handleOtherInputClick = () => {
    if (activeAskButton !== 0) {
      dispatch(updateDonationAmount(0));
      setIsOthersActive(true);
      setActiveAskButton(0);
    }
  };

  const renderPointsCard = () => {
    if (!pointsCardType) {
      return;
    }

    const [cardBrand, ...cardTypes] = pointsCardType.split("|");
    const mappedCardTypes = cardTypes?.map((type) => {
      if (type === "PA") return "monthly";
      return "one-time";
    });

    const pointsCardVariants = {
      Aeroplan: mappedCardTypes.includes(
        donationInfo.donationType as TDonationType,
      ) && <Aeroplan />,
    };

    return pointsCardVariants[cardBrand as PointsCardVariant];
  };

  return (
    <FormPartContainer currentForm={currentForm} formID={0}>
      {/* CALL CENTER TRIBUTE REPORT BUTTON */}
      {presValue === "CC" && (
        <CCReportTributeLink
          href={process.env.REACT_APP_REPORT_TRIBUTE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {t("Tribute Report")}
        </CCReportTributeLink>
      )}

      <SectionHeading>{t("What type of gift")}</SectionHeading>
      {monthlySuggestionText.en && monthlySuggestionText.fr && (
        <MonthlySuggestion />
      )}
      <DonationTypeSection>
        <TypeButtonsContainer id="donationType" data-cy="typeButtonsContainer">
          {isOneTimeSupported && (
            <TypeButton
              value="one-time"
              isSelected={donationInfo.donationType === "one-time"}
              onClick={handleTypeClick}
            >
              {t("one-time")}
            </TypeButton>
          )}

          {isMonthlySupported && (
            <TypeButton
              value="monthly"
              isSelected={donationInfo.donationType === "monthly"}
              onClick={handleTypeClick}
            >
              {t("monthly")}
            </TypeButton>
          )}
        </TypeButtonsContainer>
        {formErrors.donationType && (
          <ErrorMessage>
            <span>!</span> {t(formErrors.donationType)}
          </ErrorMessage>
        )}
      </DonationTypeSection>
      <SectionHeading>{t("Choose gift amount")}:</SectionHeading>
      <AmountSection>
        <AmountButtonsContainer
          id="donationAmount"
          data-cy="amountButtonsContainer"
        >
          {donationInfo.donationType
            ? askAmounts[donationInfo.donationType].map((ask, index) => (
                <AmountButton
                  isSelected={activeAskButton === index + 1}
                  data-id={index + 1}
                  value={ask}
                  key={index}
                  onClick={handleAmountClick}
                >
                  {ask}
                </AmountButton>
              ))
            : askAmounts["one-time"].map((ask, index) => (
                <AmountButton
                  isSelected={activeAskButton === index + 1}
                  data-id={index + 1}
                  value={ask}
                  key={index}
                  onClick={handleAmountClick}
                >
                  {ask}
                </AmountButton>
              ))}

          <OtherInputContainer
            data-cy="othersInputContainer"
            onClick={handleOtherInputClick}
          >
            <OtherLabel isSelected={isOthersActive} htmlFor="otherAmount">
              {t("Other")}:
            </OtherLabel>
            <input
              type="text"
              id="otherAmount"
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                analyticsAPI.handleOtherSelected(appInsights, e);
              }}
              onChange={handleOtherInputChange}
              value={othersValue}
            />
          </OtherInputContainer>
        </AmountButtonsContainer>
        {formErrors.donationAmount && (
          <ErrorMessage>
            <span>!</span> {t(formErrors.donationAmount)}
          </ErrorMessage>
        )}
      </AmountSection>

      {isCoverFeeSupported && (
        <CoverFeeContainer>
          <CoverFeeText>{t("Did you know you can cover fees")}</CoverFeeText>
          <CheckboxContainer data-cy="feeCover">
            <input
              type="checkbox"
              id="feeCover"
              checked={coverTransactionFee}
              onChange={() => handleTransactionFee()}
            />
            <label htmlFor="feeCover">{t("Agree to cover fee")}</label>
          </CheckboxContainer>
        </CoverFeeContainer>
      )}

      {isPointsCardSupported && renderPointsCard()}

      {donationInfo.donationType !== "monthly" &&
        isMemorySupported &&
        isHonourSupported &&
        assetsAvailable && (
          <>
            <HorizontalLine color="#D9D9D9" />
            <CheckboxContainer data-cy="inHonour">
              <input
                type="checkbox"
                id="inHonour"
                checked={isTributeGift}
                onChange={() => dispatch(updateIsTributeGift(!isTributeGift))}
              />
              <label htmlFor="inHonour">{t("In Honour Gift Checkbox")}</label>
            </CheckboxContainer>

            <Suspense fallback={<p>Loading...</p>}>
              {isTributeGift && <TributeForm handleNextForm={handleNextForm} />}
            </Suspense>
          </>
        )}

      <BottomButtonsContainer>
        <NextStepButton onClick={handleNextForm}>
          {t("Next Step")}
        </NextStepButton>
      </BottomButtonsContainer>
    </FormPartContainer>
  );
};

export default DonationType;
